import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import api from 'services/api';
import { showMessage } from 'services/utility';

import { getProduct } from "store/product-detail/actions";

import { addProductToDB, addProduct } from "store/cart/actions";

import { getCart } from "store/cart/actions";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from 'components/Loader';

import InsIcon from '../../resources/images/ins-icon.png';
import InsImg from '../../resources/images/ins-img.png';
import CueIcon from '../../resources/images/cue-icon.png';
import Cue from '../../resources/images/cue.png';
import visionary6 from '../../resources/images/visionary-6.jpg';
import visionary9 from '../../resources/images/visionary-9.jpg';
import visionary12 from '../../resources/images/visionary-12.jpg';
import hustle6 from '../../resources/images/hustle-6.jpg';
import hustle9 from '../../resources/images/hustle-9.jpg';
import hustle12 from '../../resources/images/hustle-12.jpg';
import transformation6 from '../../resources/images/transformation-6.jpg';
import transformation9 from '../../resources/images/transformation-9.jpg';
import transformation12 from '../../resources/images/transformation-12.jpg';


export default function ProductDetails() {
    let { slug } = useParams();
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth);
    const cartState = useSelector((state) => state.cart);
    const productState = useSelector((state) => state.product);
    let obj = productState.product.data?.options.find(o => o.is_default === 1);

    const [productData, setProductData] = useState({});
    const [localLoader, setLocalLoader] = useState(false);

    useEffect(() => {
        dispatch(getProduct(slug));
    }, [slug, dispatch]);

    useEffect(() => {
        setProductData({
            optionId: obj?.option_id,
            optionName: obj?.option_name,
            imageName: obj?.image_name,
            price: obj?.price,
            showMsg: obj?.show_msg,
            optionName: obj?.option_name
        });
    }, [obj]);

    const handleOptions = (optionId, imageName, price, showMsg, optionName) => {
        setProductData({
            optionId,
            imageName,
            price,
            showMsg,
            optionName
        });
    };

    async function handleAddToCart() {
        // alert(cartState.data?.length)
        // setLocalLoader(true)
        if (authState.isAuthenticated) {
            const payload = [{ product_id: productState.product.data.id, option_id: productData.optionId }]
            dispatch(addProductToDB(payload, authState.token));
            // setLocalLoader(false)
        } else {
            const payload = [{ product_id: productState.product.data.id, option_id: productData.optionId, price: productData.price, show_msg: productData.showMsg, product_name: productState.product.data.name, option_name: productData.optionName }]
            // if (cartState?.data?.length) {
            //     // let newPayload = [];
            //     const newPayload = cartState.data.concat(payload);
            //     dispatch(addProduct(newPayload));
            // } else {
            //     dispatch(addProduct(payload));
            // }
            dispatch(addProduct(payload));
            // setLocalLoader(false)
        }
    }

    return (
        <div>
            {cartState.loading ? <Loader /> : ''}
            <Header />
            {productState.product.loading ? (
                // <div className="App text-center pt-5">
                //     <span className="h3 ">Loading...</span>
                // </div>
                <Loader />
            ) : (
                <main>
                    {/* Culture Sec */}
                    <div className="container">
                        <div className="product-d-top">
                            <Fade left>
                                <div className="product-d-left">
                                    <img src={`${productState.product.metadata?.image_url}${productData.imageName}?version=${productState.product.data?.updated_at}`} alt="" />
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="product-d-right">
                                    <h3 className="heading">{productState.product.data?.name}</h3>
                                    <p>{productState.product.data?.short_description}</p>
                                    <span className="amount"><sup>$</sup>{productData.price}</span>
                                    <ul className="cart-numb">
                                        {
                                            productState.product.loading ? (
                                                <div className="App text-center pt-5">
                                                    <span className="h3 ">Loading...</span>
                                                </div>
                                            ) :
                                                (productState.product.data?.options?.length ? productState.product.data.options?.map(item => (
                                                    <li key={item.id} onClick={() => handleOptions(item.option_id, item.image_name, item.price, item.show_msg, item.option_name)} className={`${productData.optionId === item.option_id ? 'active' : ''}`}>
                                                        {item.option_name}
                                                    </li>
                                                )) : (
                                                    <div className="col-sm-12 text-center pt-5">
                                                        <span className="h1">No option found.</span>
                                                    </div>
                                                )
                                                )
                                        }
                                    </ul>
                                    <small className="choose-n">Please choose the number of wall tiles</small>
                                    <button className="button" onClick={() => handleAddToCart()}>ADD TO CART</button>
                                    {
                                        productData.showMsg ? (
                                            <div className="congratulations">
                                                <p>
                                                    <span>congratulations! </span><br />
                                                    You can avail  30 minutes of
                                                    free consultation with the Gapingvoid
                                                </p>
                                            </div>) : ''
                                    }
                                </div>
                            </Fade>
                        </div>
                        <Fade bottom>
                            <div className="ins-sec">
                                <h3 className="flex-heading"><figure><img src={InsIcon} alt="" /></figure>Instructions</h3>
                                <div className="ins-left">
                                    <p className="green">Culture Walls Hanging Instructions</p>
                                    <p>The Culture Wall tiles are 12X12 inches and should be hung up 2 inches apart in a grid format.</p>
                                    <p>They are super simple to hang with a single nail as they hang directly from the back of the frame ( no wires needed).</p>
                                    <p>You just need someone who is good with a measuring tape and can spec. out the grid.</p>
                                    <p className="blue">If you have any questions or concerns feel free to reach out to us at culture@gapingvoid.com</p>
                                </div>
                                <div className="ins-right">
                                    <p>Your handyman/woman will need:</p>
                                    <img src={InsImg} alt="" />
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="ins-sec">
                                <h3 className="flex-heading"><figure><img src={CueIcon} alt="" /></figure>Visual Cue</h3>
                                <div className="ins-left">
                                    <p>Nail is placed centered at the top
                                        of each tile (6in)</p>
                                    <p>Each tile hangs directly from the
                                        back of the frame.</p>
                                </div>
                                <div className="ins-right">
                                    <img src={Cue} alt="" />
                                </div>
                            </div>
                        </Fade>
                        <ul className="product-detail-list">
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={visionary6} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={visionary9} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={visionary12} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={hustle6} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={hustle9} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={hustle12} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={transformation6} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={transformation9} alt="" />
                                    </div>
                                </li>
                            </Fade>
                            <Fade bottom>
                                <li>
                                    <div className="img">
                                        <img src={transformation12} alt="" />
                                    </div>
                                </li>
                            </Fade>
                        </ul>
                    </div>
                </main>)}
            <Footer />
        </div>
    );
}