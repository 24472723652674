export const GET_ADDRESS_BEGIN = 'GET_ADDRESS_BEGIN';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';

export const GET_ADDRESSBYID_BEGIN = 'GET_ADDRESSBYID_BEGIN';
export const GET_ADDRESSBYID_SUCCESS = 'GET_ADDRESSBYID_SUCCESS';
export const GET_ADDRESSBYID_FAIL = 'GET_ADDRESSBYID_FAIL';

export const ADD_ADDRESS_BEGIN = 'ADD_ADDRESS_BEGIN';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL';

export const GET_ADDRESSBYID_RESET = 'GET_ADDRESSBYID_RESET';