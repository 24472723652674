import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from 'services/api';
import { showMessage } from 'services/utility';

import { getAddressById } from "store/address/actions";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';

import Sidebar from './components/sidebar';

export default function AddAddress() {
    let { id } = useParams();
    const authState = useSelector((state) => state.auth);
    const addressState = useSelector((state) => state.address.addressById);
    const [localLoader, setLocalLoader] = useState(false);

    const [user, setUser] = useState({
        name: '',
        mobile: '',
        address: '',
        pin: '',
        locality: '',
    });

    const [formErrors, setFormErrors] = useState({});

    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setUser({
                name: addressState?.data?.name,
                mobile: addressState?.data?.phone,
                address: addressState?.data?.address,
                pin: addressState?.data?.postal_code,
                locality: addressState?.data?.locality,
            });
        } else {
            setFormErrors({
                name: '',
                mobile: '',
                address: '',
                pin: '',
                locality: '',
            });
        }
    }, [id, dispatch]);

    function handleChange(e) {
        const { name, value } = e.target;
        let currentFormErrors = formErrors;

        switch (name) {
            case 'name':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Name';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'mobile':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Mobile';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'address':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter address';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'pin':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Pincode';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'locality':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Mobile';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'city':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter City';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'state':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter State';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            default:
                break;
        }
        setUser({ ...user, [name]: value });
        setFormErrors(currentFormErrors);
    }

    const addressFormSubmit = async (e) => {
        try {
            e.preventDefault();
            setLocalLoader(true);
            const paramId = id ? id : 0;
            const result = await api.post(`address/${paramId}`,
                {
                    name: user.name,
                    phone: user.mobile,
                    postal_code: user.pin,
                    locality: user.locality,
                    address: user.address,
                },
                {
                    headers: {
                        'token': authState.token,
                    }
                }
            );

            if (result && result.code == 200) {
                dispatch(getAddressById({ id, token: authState.token }))
                setLocalLoader(false);
                navigate('/profile/manage-address');
            } else {
                setLocalLoader(false);
                showMessage('error', result.message);
            }
        } catch (err) {
            setLocalLoader(false);
            showMessage('error', err.message);
        }
    }

    return (
        <div>
            {localLoader ? <Loader /> : ''}
            <Header />
            <main>
                {/* Manage Address Sec */}
                <div className="container">
                    <div className="manage-a-inner">
                        <Sidebar />
                        <div className="address-sec">
                            <div className="row">
                                <div className="col-sm-12 mb-2">
                                    <h3>{id ? 'Edit Address' : 'Add New Address'}</h3>
                                </div>
                            </div>
                            <form onSubmit={addressFormSubmit} noValidate>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label>Name <span className="error-star">*</span></label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={user.name}
                                                className="form-control"
                                                noValidate
                                                onChange={handleChange}
                                            />
                                            {formErrors && formErrors.name ? (<p className="error">{formErrors.name}</p>) : ''}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label>Mobile Number <span className="error-star">*</span></label>
                                            <input
                                                type="text"
                                                value={user.mobile}
                                                name="mobile"
                                                className="form-control"
                                                noValidate
                                                onChange={handleChange}
                                            />
                                            {formErrors && formErrors.mobile ? (<p className="error">{formErrors.mobile}</p>) : ''}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label>Pin Code <span className="error-star">*</span></label>
                                            <input
                                                type="text"
                                                value={user.pin}
                                                name="pin"
                                                className="form-control"
                                                noValidate
                                                onChange={handleChange}
                                            />
                                            {formErrors && formErrors.pin ? (<p className="error">{formErrors.pin}</p>) : ''}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label>Locality <span className="error-star">*</span></label>
                                            <input
                                                type="text"
                                                value={user.locality}
                                                name="locality"
                                                className="form-control"
                                                noValidate
                                                onChange={handleChange}
                                            />
                                            {formErrors && formErrors.locality ? (<p className="error">{formErrors.locality}</p>) : ''}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group mb-4">
                                            <label>Address </label>
                                            <textarea
                                                name="address"
                                                value={user.address}
                                                className="form-control"
                                                noValidate
                                                onChange={handleChange}
                                            />
                                            {formErrors && formErrors.address ? (<p className="error">{formErrors.address}</p>) : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-outer">
                                    <button type="submit" className={`button ${Object.entries(formErrors || {}).length > 0 ? 'disabled' : ''}`} disabled={Object.entries(formErrors || {}).length > 0}>Save</button>
                                    <button type="button" className="button border" onClick={() => navigate('/profile/manage-address')}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}