import { combineReducers } from "redux";

import auth from "./auth";
import user from "./user";
import cart from "./cart";
import products from "./products";
import category from "./category";
import product from "./product-detail"
import address from "./address"
import quiz from "./quiz"
import casestudy from "./case-study";


const rootReducer = combineReducers({
  auth,
  user,
  cart,
  products,
  category,
  product,
  address,
  quiz,
  casestudy
});

export default rootReducer;
