import React, { useState } from 'react';

export default function CheckoutProduct(props) {
    return (
        <>
            <div className="checkout-right">
                <ul className="order-list">
                    {!props.cartState?.products?.length ? (
                        <div className="App text-center pt-5">
                            <span className="1">Your cart is empty.</span>
                        </div>
                    ) : (
                        props.cartState.products.map(item => (
                            <li key={item.id}>
                                <div className="ord-name">
                                    <div className="img"><img src={`${props.cartState.metadata.image_url}${item.product_image}`} alt="" /></div>
                                    <strong>{item.product_name} </strong>
                                </div>
                                <div className="ord-frames">
                                    <strong>Frames</strong>
                                    {item.option_name}
                                </div>
                                <div className="ord-price">
                                    <strong>Price</strong>
                                    ${item.price}
                                </div>
                            </li>)))
                    }
                </ul>
                {
                    props?.cartState?.metadata?.show_msg ?
                        <table className="table border-none">
                            <tbody><tr>
                                <td>30 min consultation - Gaping Void</td>
                                <td className="text-center" />
                                <td className="text-right"><span>Complimentary</span></td>
                            </tr>
                            </tbody>
                        </table> : ''
                }
                <ul className="subtotal">
                    {
                        props?.cartState?.metadata?.sub_total ?
                            <li>
                                <div className="text-left">Subtotal</div>
                                <div className="text-right">${props.cartState.metadata.sub_total}</div>
                            </li> : ''
                    }
                    <li>
                        <div className="text-left">Shipping</div>
                        <div className="text-right">
                            <div className="green">Free</div>
                        </div>
                    </li>
                    {
                        props?.cartState?.metadata?.sub_total ?
                            <li>
                                <div className="black">Total</div>
                                <div className="black text-right">${props.cartState.metadata.sub_total}</div>
                            </li> : ''
                    }
                </ul>
            </div>
        </>
    );
}
