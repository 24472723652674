import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider(props) {
    var settings = {
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        slidesToScroll: -1,
        responsive: [
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Slider {...settings}>
            {
                props?.data?.data?.testimonial?.length ? props.data.data.testimonial?.map(item => (
                    !item.is_rtl ?
                        <div key={item.id}>
                            <div className="testimonial-box-m">
                                <div className="testimonial-box">
                                    <div className="img">
                                        <img src={`${props.data.metadata.testimonial_image_url}${item.image_name}`} alt="" />
                                    </div>
                                    <div className="testimonial-text">
                                        <p>{item.description}</p>
                                        <strong>{item.name}</strong>
                                        <span>{item.location}</span>
                                    </div>
                                </div>
                            </div>
                        </div> : ''
                )) : ''
            }
        </Slider>
    );
}