import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Fade from 'react-reveal/Fade';

import { getCategory } from "store/category/actions";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';

export default function Themes() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const category = useSelector((state) => state.category);

    useEffect(() => {
        dispatch(getCategory());
    }, []);

    return (

        <div>
            <Header />
            <main>
                {/* Culture Sec */}
                <div className="container">
                    <ul className="collection-list">
                        {
                            category.category.loading ? (
                                // <div className="App text-center pt-5">
                                //     <span className="h3 ">Loading...</span>
                                // </div>
                                <Loader />
                            ) :
                                (category.category.data?.length ? category.category.data?.map(item => (
                                    <Fade bottom>
                                        <li>
                                            <Fade top>
                                                <h3 className="heading">{item.name}</h3>
                                                <p>{item.short_description}</p>
                                                <a className="button" onClick={() => navigate(`/products/${item.slug}`)}>EXPLORE  THEME</a>
                                            </Fade>
                                            <Fade bottom>
                                                <div className="collection-img">
                                                    <img src={`${category.category.metadata.image_url}${item.image_name}?version=${item.updated_at}`} alt="" />
                                                </div>
                                            </Fade>
                                        </li>
                                    </Fade>
                                )) : (
                                    <div className="col-sm-12 text-center pt-5">
                                        <span className="h3 ">No category found.</span>
                                    </div>
                                )
                                )
                        }
                    </ul>
                </div>
            </main>
            <Footer />
        </div>
    );
}