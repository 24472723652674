import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/sidebar'

import OrderIcon from '../../resources/images/order-icon1.png';

export default function Order() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <main>
                {/* Manage Address Sec */}
                <div className="container">
                    <div className="manage-a-inner">
                        <div className="tab-outer">
                            <div className="name-outer">
                                <small>Hello,</small>
                                <h6>Name</h6>
                            </div>
                            <Sidebar />
                        </div>
                        <div className="address-sec">
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <h3>Order Detail</h3>
                                </div>
                            </div>
                            <ul className="order-list">
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming </strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        9
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                    <div className="ord-staus">
                                        <strong><em className="red-circle" />Order Not Placed</strong>
                                        <small>Your payment was not confirmed by bank</small><br />
                                        <button className="button delete xsmall">Cancel Order</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming is the discipline of deciding</strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        9
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                    <div className="ord-staus">
                                        <strong><em className="green-circle" />Order Delevered</strong>
                                        <small>Your payment has been confirmed</small>
                                    </div>
                                </li>
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming </strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        9
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                    <div className="ord-staus">
                                        <strong><em className="red-circle" />Order Not Placed</strong>
                                        <small>Your payment was not confirmed by bank</small><br />
                                        <button className="button delete xsmall">Cancel Order</button>
                                    </div>
                                </li>
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming is the discipline of deciding</strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        9
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                    <div className="ord-staus">
                                        <strong><em className="green-circle" />Order Delevered</strong>
                                        <small>Your payment has been confirmed</small>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

// const mapDispatch = { login };

// export default connect(null, mapDispatch)(Themes);