import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'

import OrderIcon from '../../../resources/images/order-icon.png';
import ManageIcon from '../../../resources/images/manage-icon.png';
import ResetIcon from '../../../resources/images/reset-icon.png';
import LogoutIcon from '../../../resources/images/logout-icon.png';

export default function Sidebar(props) {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const userState = useSelector((state) => state.user);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="tab-outer">
                <div className="name-outer">
                    <small>Hello,</small>
                    <h6>{userState.name}</h6>
                </div>
                <ul className="tab-list">
                    <li onClick={() => navigate('/profile/order')}>
                        <figure><img src={OrderIcon} alt="" /></figure> Order
                    </li>
                    <li onClick={() => navigate('/profile/manage-address')}>
                        <figure><img src={ManageIcon} alt="" /></figure> Manage Address
                    </li>
                    <li onClick={() => navigate('/profile/reset-password')}>
                        <figure><img src={ResetIcon} alt="" /></figure> Reset Password
                    </li>
                    <li>
                        <figure><img src={LogoutIcon} alt="" /></figure> Logout
                    </li>
                </ul>
            </div>
        </>
    );
}
