import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import CartIcon from '../resources/images/cart-icon.png';

export default function Cart(props) {
    const navigate = useNavigate();
    // const cartState = useSelector((state) => state.cart.products);

    // useEffect(() => {
    //     console.log(cartState.length, 'lllll')
    // }, []);
    // alert(props.showMessage)

    return (
        <>
            <div className={`popup-outer ${props.modalShow ? 'show' : ''}`} id="cart-popup">
                <div className="popup-overlay" />
                <div className="popup-sec">
                    <div className="popup-close" onClick={props.handleClose} />
                    <div className="popup-top">
                        <h3 className="flex-heading">
                            <figure><img src={CartIcon} alt="" /></figure> My cart
                        </h3>
                        {!props.cartState?.length ? (
                            <div className="App text-center pt-5">
                                <span className="1">Your cart is empty.</span>
                            </div>
                        ) : (
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th>Culture Wall</th>
                                        <th className="text-center">Frames</th>
                                        <th className="text-right">Price</th>
                                    </tr>
                                    {
                                        props.cartState?.length ? props.cartState?.map(item => (
                                            <tr>
                                                <td><div className="delete-item" onClick={() => props.handleDelete(item.id, item.product_id, item.option_id)} />{item?.product_name}</td>
                                                <td className="text-center">{item?.option_name}</td>
                                                <td className="text-right">{item?.price}</td>
                                            </tr>
                                        )) : ''
                                    }
                                    {
                                        // props.cartState.data?.length && props.cartState.metadata?.show_msg ?
                                        props.showMessage ?
                                            <tr>
                                                <td>30 min consultation - Gaping Void</td>
                                                <td className="text-center" />
                                                <td className="text-right"><span>Complimentary</span></td>
                                            </tr> : ''
                                    }
                                    {
                                        props.cartState?.length ?
                                            <tr>
                                                <td className="last-ch">Subtotal</td>
                                                <td className="last-ch text-center" />
                                                {/* <td className="last-ch text-right">${props.cartState.metadata?.sub_total}</td> */}
                                                <td className="last-ch text-right">${props.cartTotal}</td>
                                            </tr> : ''
                                    }
                                </tbody>
                            </table>
                        )}
                        {
                            props.cartState?.length ?
                                <div className="text-center">
                                    <button className="button" onClick={props.handleCheckout}>Proceed to checkout</button>
                                </div> : ''
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
