import api from 'services/api';
import {
    GET_ADDRESS_BEGIN,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAIL,

    GET_ADDRESSBYID_BEGIN,
    GET_ADDRESSBYID_SUCCESS,
    GET_ADDRESSBYID_FAIL,

    ADD_ADDRESS_BEGIN,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAIL,

    GET_ADDRESSBYID_RESET

} from "./types";

import { handleResponse } from 'store/common/actions';

export const getAddress = (token) => async (dispatch) => {
    try {
        dispatch({ type: GET_ADDRESS_BEGIN });

        const address = await api.get('address', {}, {
            headers: {
                'token': token,
            }
        });

        dispatch(handleResponse(address));

        dispatch({ type: GET_ADDRESS_SUCCESS, payload: address })
    } catch (err) {
        dispatch({ type: GET_ADDRESS_FAIL, payload: err.message });
    }
}

export const getAddressById = (payload) => async (dispatch) => {
    try {
        dispatch({ type: GET_ADDRESSBYID_BEGIN });
        const { id } = payload;
        const address = await api.get(`address/${id}`, {}, {
            headers: {
                'token': payload.token,
            }
        });

        dispatch(handleResponse(address));

        dispatch({ type: GET_ADDRESSBYID_SUCCESS, payload: address })
    } catch (err) {
        dispatch({ type: GET_ADDRESSBYID_FAIL, payload: err.message });
    }
}

export const getAddressByIdReset = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ADDRESSBYID_RESET });
    } catch (err) {
        // dispatch({ type: GET_ADDRESSBYID_FAIL, payload: err.message });
    }
}

export const addAddress = (payload, paramId, token) => async (dispatch) => {
    try {
        dispatch({ type: ADD_ADDRESS_BEGIN });

        const address = await api.post(`address/${paramId}`, payload, {
            headers: {
                'token': token,
            }
        });

        dispatch(handleResponse(address));

        dispatch({ type: ADD_ADDRESS_SUCCESS, payload: address })
    } catch (err) {
        dispatch({ type: ADD_ADDRESS_FAIL, payload: err.message });
    }
}