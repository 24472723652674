import React, { useState } from 'react';

import api from 'services/api';
import { showMessage, minMaxLength, validEmail, validateConfirmPassword } from 'services/utility';

export default function Signup(props) {
    const [apiError, setApiError] = useState('');
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        confirm_password: ''
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        password: '',
        confirm_password: ''
    });

    function handleChange(e) {
        const { name, value } = e.target;
        let currentFormErrors = formErrors;

        switch (name) {
            case 'name':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Name';
                } else if (minMaxLength(value, 3)) {
                    currentFormErrors[name] = 'Name should have minimum 3 characters';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'email':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Email';
                } else if (validEmail(value)) {
                    currentFormErrors[name] = 'Please Enter a valid email';
                }
                else {
                    delete currentFormErrors[name];
                }
                break;
            case 'password':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Password';
                } else if (minMaxLength(value, 6)) {
                    currentFormErrors[name] = 'Password should have minimum 6 characters';
                } else {
                    delete currentFormErrors[name];
                }

                if (validateConfirmPassword(value, user.confirm_password)) {
                    currentFormErrors['confirm_password'] = 'Confirmed password is not matching with password';
                } else {
                    delete currentFormErrors['confirm_password'];
                }
                break;
            case 'confirm_password':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Confirm Password';
                } else if (validateConfirmPassword(user.password, value)) {
                    currentFormErrors[name] = 'Confirmed password is not matching with password';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            default:
                break;
        }
        setUser({ ...user, [name]: value });
        setFormErrors(currentFormErrors);
    }

    const signupFormSubmit = async (e) => {
        try {
            e.preventDefault();
            setApiError('');
            const { name, email, password } = user;

            if (!email) throw new Error('Email can not be empty');
            if (!password) throw new Error('Password can not be empty');

            const result = await api.post('signup', { name, email, password, device_id: '1234567588' });

            if (result && result.code == 200) {
                props.handleClose()
            } else {
                setApiError(result.message);
            }
        } catch (err) {
            showMessage('error', err.message);
        }
    }

    return (
        <>
            <div className={`popup-outer ${props.modalShow ? 'show' : ''}`} id="SignUP">
                <div className="popup-overlay" />
                <div className="popup-sec">
                    <div className="popup-close" onClick={props.handleClose} />
                    {/* Login */}
                    <div className="or-text"><strong>Sign Up</strong></div>
                    <form className="login-form" onSubmit={signupFormSubmit} noValidate>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group mb-3">
                                    <label>Name <span className="error-star">*</span></label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        noValidate
                                        onChange={handleChange}
                                    />
                                    {formErrors && formErrors.name ? (<p className="error">{formErrors.name}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-3">
                                    <label>Email <span className="error-star">*</span></label>
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        noValidate
                                        onChange={handleChange}
                                    />
                                    {formErrors && formErrors.email ? (<p className="error">{formErrors.email}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-3">
                                    <label>Password <span className="error-star">*</span></label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        noValidate
                                        onChange={handleChange}
                                    />
                                    {formErrors && formErrors.password ? (<p className="error">{formErrors.password}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-3">
                                    <label>Confirm Password <span className="error-star">*</span></label>
                                    <input
                                        type="password"
                                        name="confirm_password"
                                        className="form-control"
                                        noValidate
                                        onChange={handleChange}
                                    />
                                    {formErrors && formErrors.confirm_password ? (<p className="error">{formErrors.confirm_password}</p>) : ''}
                                </div>
                            </div>
                        </div>
                        {apiError ? <p class="error text-center">{apiError}!</p> : ''}
                        <div className="btn-outer">
                            <button type="button" className="button border" onClick={props.handleClose}>Cancel</button>
                            <button type="submit" className={`button ${Object.entries(formErrors || {}).length > 0 ? 'disabled' : ''}`} disabled={Object.entries(formErrors || {}).length > 0}>Sign Up</button>
                        </div>
                    </form>
                    {/* Already have an account? */}
                    <div className="already-signup"><span>Already have an account? <button type="button" className="green-link" onClick={props.handleLoginModal}>Sign In</button></span></div>
                </div>
            </div>
        </>
    );
}
