import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import api from 'services/api';
import { showMessage } from 'services/utility';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';

import { getAddress, getAddressById } from "store/address/actions";

import Sidebar from './components/sidebar';

export default function ManageAddress() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth);
    const addressState = useSelector((state) => state.address);

    useEffect(() => {
        dispatch(getAddress(authState.token));
    }, [authState, dispatch]);

    async function handleDeleteAddress(id) {
        try {
            const result = await api.delete(`address/${id}`, {},
                {
                    headers: {
                        'token': authState.token,
                    }
                }
            );

            if (result && result.code == 200) {
                dispatch(getAddress(authState.token));
            } else {
                showMessage('error', result.message);
            }
        } catch (err) {
            showMessage('error', err.message);
        }
    }

    async function handleEditAddress(id) {
        dispatch(getAddressById({ id, token: authState.token })).then(response => {
            navigate(`/profile/manage-address/address/${id}`)
        });
    }

    return (
        <div>
            {/* {addressState.loading ? <Loader /> : ''} */}
            <Header />
            {addressState?.loading ? (
                <Loader />
            ) : (
                <main>
                    {/* Manage Address Sec */}
                    <div className="container">
                        <div className="manage-a-inner">
                            <Sidebar />
                            <div className="address-sec">
                                <div className="row">
                                    <div className="col-sm-6 mb-2">
                                        <h3>Manage Address</h3>
                                    </div>
                                    <div className="col-sm-6 mb-3 text-right">
                                        <button className="button small" onClick={() => navigate('/profile/manage-address/address')}>+ Add A New Address</button>
                                    </div>
                                </div>
                                <ul className="manage-address">
                                    {
                                        addressState.address.data?.length ? addressState.address.data?.map(item => (
                                            <li key={item.id}>
                                                <strong>{item.name} <span>{item.phone}</span></strong>
                                                <p>{`${item.address ? item.address : ''} ${item.locality ? item.locality : ''} ${item.landmark ? item.landmark : ''} ${item.city ? item.city : ''} ${item.state ? item.city : ''} ${item.postal_code ? item.postal_code : ''}`}</p>
                                                <ul className="edit-delete">
                                                    <li><button className="button border xsmall" onClick={() => handleEditAddress(item.id)}>Edit</button></li>
                                                    <li><button className="button delete xsmall" onClick={() => handleDeleteAddress(item.id)}>Delete</button></li>
                                                </ul>
                                            </li>
                                        )) :
                                            <div className="col-sm-12 text-center pt-5">
                                                <span className="h1">No address found.</span>
                                            </div>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </main>)}
            <Footer />
        </div>
    );
}