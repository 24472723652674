import React from 'react';
import Fade from 'react-reveal/Fade';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LRImage from '../../resources/images/l-r-img1.png';

export default function HowItWorks() {
    return (

        <div>
            <Header />
            <main>
                <div className="container">
                    <Fade bottom>
                        <div className="head mt-5 mb-0">
                            <h3 className="heading text-center">Culture Walls®</h3>
                            <p className="fontSize24"><strong>Each Culture Wall theme represents a different set of articulated beliefs that speak to a “flavor of entrepreneur” persona and their business culture philosophy.</strong></p>
                        </div>
                    </Fade>
                </div>
                {/* left-right-sec */}
                <section className="left-right-sec">
                    <div className="container">
                        <div className="left-right-inner">
                            <Fade left>
                                <div className="l-r-img">
                                    <img src={LRImage} alt="" />
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="l-r-text">
                                    <h3 className="heading">Culture: <br />
                                        The Product of Beliefs</h3>
                                    <p><small>Any great leader knows that there are a number of fundamentals to leadership. But three essential ones are:</small></p>
                                    <p>First, beliefs inform behaviors. Groups of people who share a common perspective to life, business, people, work together with less friction, collaborate with ease and are connected to a common goal. </p>
                                    <p>They maintain the momentum that the leader initiates.</p>
                                </div>
                            </Fade>
                        </div>
                        {/* Second, in order to deeply instill beliefs */}
                        <Fade bottom>
                            <div className="deeply-text">
                                <p>Second, in order to deeply instill beliefs, you need tools. These tools are variously referred to as cultural artifacts, totems, or social objects. You can think of many religious or political icons, that do this. These objects live, often for millennia, for a reason – they are constant reminders of what beliefs matter. People live with these objects and the beliefs they represent become integrated into their lives.</p>
                                <p>Third, leading people is a complex mixture of logic and emotion. The outcomes you are leading towards must make sense, but if you really want results, it is essential to link emotions to these outcomes</p>
                            </div>
                        </Fade>
                    </div>
                </section>
                {/* After many years of working */}
                <div className="container">
                    <Fade bottom>
                        <div className="deeply-box">
                            <p>After many years of working in enterprise culture change for companies like Microsoft, Zappos, and L’Oreal, we realized that organizations need tools to reinforce their beliefs and commitments to how much their work matters.</p>
                            <div className="btn-outer">
                                <button type="button" className="button border">COMISSION A CULTURE WALL </button>
                                <button type="submit" className="button">EXPLORE THEMES</button>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/* How It Works:  */}
                <div className="container">
                    <Fade bottom>
                        <div className="head mb-0">
                            <h3 className="heading">How It Works: <br />
                                The Culture Science©
                                Process</h3>
                            <p>We will deliberately design and execute the strategies and tools you need to implement ideas at scale, throughout your organization, to drive new thinking and better performance for everyone in your space.</p>
                        </div>
                    </Fade>
                </div>
                {/* left-right-sec */}
                <section className="left-right-sec">
                    <div className="container">
                        <div className="left-right-inner">
                            <Fade left>
                                <div className="l-r-text">
                                    <h4>We begin with a comprehensive culture assessment, which can be done on location or remotely. This helps us identify what to fix and where to innovate. </h4>
                                    <p className="mb-5"><small>(1. Culture Science Assessment® and readiness report).</small></p>
                                    <p>Strategies and tools are then designed that will give you the real buy-in and engagement you need across all stakeholder groups (2. seeding employees and other stakeholders with the necessary ideas and behaviors to unlock new thinking).</p>
                                    <p>While new ideas are often hard for many people to understand or accept, we take a human-centered approach. </p>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="l-r-img">
                                    <img src={LRImage} alt="" />
                                </div>
                            </Fade>
                        </div>
                        <Fade bottom>
                            <div className="deeply-text">
                                <p className="green"><strong>Our expertise involves humanizing and scaling change concepts by appealing to each individual’s wants and needs across your organization, and then making them believe and feel that they too, are part of a winning team on an inspiring mission to change.</strong></p>
                            </div>
                        </Fade>
                    </div>
                </section>
                {/* left-right-sec */}
                <section className="left-right-sec">
                    <div className="container">
                        <div className="left-right-inner">
                            <Fade left>
                                <div className="l-r-img">
                                    <img src={LRImage} alt="" />
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="l-r-text">
                                    <h4 className="mb-5">Strategy is created through a human-centered lens and then visualized to become change management tools that feel like works of art.</h4>
                                    <p>This change framework diverts substantially from the norm, in that it is driven by unlocking and engaging stakeholders to own and drive change, versus the classic enforcement-based system that is a proven failure throughout businesses today (3. Implement people-driven change).</p>
                                    <p>The next important step is to socialize your cultural tools across physical and digital spaces and social media channels to symbolize and influence changes in behaviors and mindsets to scale the changes needed. (4. driving needed changes through shared beliefs).</p>
                                </div>
                            </Fade>
                        </div>
                        <Fade bottom>
                            <div className="deeply-text">
                                <p><strong>Often Culture Walls® are themed around specific subject areas such as Diversity and Inclusion, Innovation, Values, etc</strong></p>
                            </div>
                        </Fade>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}