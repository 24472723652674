import React, { useState } from 'react';

export default function Address(props) {
    return (
        <>
            <div className={`popup-outer ${props.modalShow ? 'show' : ''}`}>
                <div className="popup-overlay" />
                <div className="popup-sec">
                    <div className="popup-close" onClick={props.handleClose} />
                    {/* Login */}
                    <div className="or-text"><strong>Add Address</strong></div>
                    <form onSubmit={props.addressFormSubmit} noValidate>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <label>Name <span className="error-star">*</span></label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={props.user.name}
                                        className="form-control"
                                        noValidate
                                        onChange={props.handleChange}
                                    />
                                    {props.formErrors && props.formErrors.name ? (<p className="error">{props.formErrors.name}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <label>Mobile Number <span className="error-star">*</span></label>
                                    <input
                                        type="text"
                                        value={props.user.mobile}
                                        name="mobile"
                                        className="form-control"
                                        noValidate
                                        onChange={props.handleChange}
                                    />
                                    {props.formErrors && props.formErrors.mobile ? (<p className="error">{props.formErrors.mobile}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <label>Pin Code <span className="error-star">*</span></label>
                                    <input
                                        type="text"
                                        value={props.user.pin}
                                        name="pin"
                                        className="form-control"
                                        noValidate
                                        onChange={props.handleChange}
                                    />
                                    {props.formErrors && props.formErrors.pin ? (<p className="error">{props.formErrors.pin}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <label>Locality <span className="error-star">*</span></label>
                                    <input
                                        type="text"
                                        value={props.user.locality}
                                        name="locality"
                                        className="form-control"
                                        noValidate
                                        onChange={props.handleChange}
                                    />
                                    {props.formErrors && props.formErrors.locality ? (<p className="error">{props.formErrors.locality}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>Address <span className="error-star">*</span></label>
                                    <textarea
                                        name="address"
                                        value={props.user.address}
                                        className="form-control"
                                        // defaultValue={""}
                                        noValidate
                                        onChange={props.handleChange}
                                    />
                                    {props.formErrors && props.formErrors.address ? (<p className="error">{props.formErrors.address}</p>) : ''}
                                </div>
                            </div>
                        </div>
                        <div className="btn-outer">
                            <button type="submit" className={`button ${Object.entries(props.formErrors || {}).length > 0 ? 'disabled' : ''}`} disabled={Object.entries(props.formErrors || {}).length > 0}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
