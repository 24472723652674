import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Fade from 'react-reveal/Fade';

import parse from 'html-react-parser';

import { getCaseStudy } from 'store/case-study/actions';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from 'components/Loader';
import Subscribe from 'components/Subscribe';

export default function CEOCulture() {
    const dispatch = useDispatch()
    const data = useSelector((state) => state.casestudy);

    useEffect(async () => {
        dispatch(getCaseStudy());
    }, [])

    return (
        <div>
            <Header />
            {data?.loading ? <Loader /> : ''}
            <main>
                <div className="container">
                    <Fade bottom>
                        <div className="head mt-5">
                            <h3 className="heading text-center">{data?.casestudy?.data?.title}</h3>
                            <p className="fontSize24"><strong>{data?.casestudy?.data?.sub_title} </strong></p>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className="editor-text">
                            <img src={`${data?.casestudy?.metadata?.image_url}${data?.casestudy?.data?.image}`} alt="" />
                        </div>
                    </Fade>
                    <Fade bottom>
                        <div className="why-sec">
                            {parse(data?.casestudy?.data?.description ? data?.casestudy.data.description : 'No description')}
                        </div>
                    </Fade>
                </div>
                <Subscribe />
            </main>
            <Footer />
        </div>
    );
}