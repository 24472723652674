import React from 'react';
import { Link } from 'react-router-dom'

import FooterLogo from '../resources/images/logo.svg';
import InstaLogo from '../resources/images/insta-icon.svg';
import TwitterLogo from '../resources/images/twitter-icon.svg';
import FBLogo from '../resources/images/fb-icon.svg';
import LinkedinLogo from '../resources/images/linkedin-icon.svg';

const Footer = () => (
    <div>
        <footer>
            <div className="container">
                <div className="footer-top-inner">
                    <div className="footer-top-left">
                        <div className="footer-logo"><img src={FooterLogo} alt="" /></div>
                        <p>Culture Walls® are simple and effective tools for scaling and integrating cultural norms. </p>
                    </div>
                    <div className="footer-top-mid">
                        <h4>Menu</h4>
                        <ul className="footer-menu">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/themes">Themes</Link></li>
                            <li><Link to="/how-it-works">How it works</Link></li>
                            <li><a href="#">Culture Science</a></li>
                            <li><Link to="/ceo-culture">Case Study</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="footer-top-right">
                        <div className="footer-social">
                            <h4>Social Media</h4>
                            <ul className="social-menu">
                                <li><a href="#"><img src={InstaLogo} alt="" /></a></li>
                                <li><a href="#"><img src={TwitterLogo} alt="" /></a></li>
                                <li><a href="#"><img src={FBLogo} alt="" /></a></li>
                                <li><a href="#"><img src={LinkedinLogo} alt="" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <ul className="footer-bootom-menu">
                        <li><a href="#">PRIVACY</a></li>
                        <li><a href="#"> TERMS OF SERVICE </a></li>
                        <li><a href="#">SITE MAP</a></li>
                    </ul>
                    <p>© Culture Walls 2022</p>
                </div>
            </div>
        </footer>
    </div>
)

export default Footer;

