import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

import api from 'services/api';
import { showMessage, minMaxLength, validEmail } from 'services/utility';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import PhoneIcon from '../../resources/images/phone-icon.svg';
import EmailIcon from '../../resources/images/email-icon.svg';
import AddressIcon from '../../resources/images/address-icon.svg';

export default function Contact() {
    const [postData, setPostData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        message: ''
    });

    function handleChange(e) {
        const { name, value } = e.target;
        let currentFormErrors = formErrors;

        switch (name) {
            case 'name':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Name';
                } else if (minMaxLength(value, 3)) {
                    currentFormErrors[name] = 'Name should have minimum 3 characters';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'email':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Email';
                } else if (validEmail(value)) {
                    currentFormErrors[name] = 'Please Enter a valid email';
                }
                else {
                    delete currentFormErrors[name];
                }
                break;
            case 'message':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Message';
                } else if (minMaxLength(value, 50)) {
                    currentFormErrors[name] = 'Message should have minimum 50 characters';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            default:
                break;
        }
        setPostData({ ...postData, [name]: value });
        setFormErrors(currentFormErrors);
    }

    const contactFormSubmit = async (e) => {
        try {
            e.preventDefault();

            const result = await api.post('contactus', { name: postData.name, email: postData.email, subject: postData.subject, message: postData.message });

            if (result && result.code == 200) {
                setPostData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
                setFormErrors({
                    name: '',
                    email: '',
                    message: ''
                });
                showMessage('success', result.message);
            } else {
                showMessage('error', result.message);
            }
        } catch (err) {
            showMessage('error', err.message);
        }
    }
    return (

        <div>
            <Header />
            <main>
                {/* Manage Address Sec */}
                <div className="container">
                    <div className="head mt-5 mb-0">
                        <h3 className="heading text-center">Contact Us</h3>
                    </div>
                    <div className="contact-inner">
                        <Fade left>
                            <div className="contact-info">
                                <h3 className="heading">Let’s chat. We’d love to hear from you.</h3>
                                <ul className="info-list">
                                    <li>
                                        <figure><img src={PhoneIcon} alt="" /></figure> 786-622-2282
                                    </li>
                                    <li>
                                        <figure><img src={EmailIcon} alt="" /></figure> hello@culturewalls.com
                                    </li>
                                    <li>
                                        <figure><img src={AddressIcon} alt="" /></figure> 1521 Alton Rd<br />
                                        STE 518<br />
                                        Miami Beach, FL 33139<br />
                                    </li>
                                </ul>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="contact-form">
                                <h3>Send us a message</h3>
                                <form onSubmit={contactFormSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group mb-4">
                                                <label>Name <span className="error-star">*</span></label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={postData.name}
                                                    className="form-control"
                                                    noValidate
                                                    onChange={handleChange}
                                                />
                                                {formErrors && formErrors.name ? (<p className="error">{formErrors.name}</p>) : ''}
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-4">
                                                <label>Email Address <span className="error-star">*</span></label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={postData.email}
                                                    className="form-control"
                                                    noValidate
                                                    onChange={handleChange}
                                                />
                                                {formErrors && formErrors.email ? (<p className="error">{formErrors.email}</p>) : ''}
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-4">
                                                <label>Subject ( optional but helpful )</label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    value={postData.subject}
                                                    className="form-control"
                                                    noValidate
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-4">
                                                <label>Message <span className="error-star">*</span></label>
                                                <textarea
                                                    name="message"
                                                    className="form-control"
                                                    value={postData.message}
                                                    noValidate
                                                    onChange={handleChange}
                                                />
                                                {formErrors && formErrors.message ? (<p className="error">{formErrors.message}</p>) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className>
                                        <button type="submit" className={`button ${Object.entries(formErrors || {}).length > 0 ? 'disabled' : ''}`} disabled={Object.entries(formErrors || {}).length > 0}>SEND</button>
                                    </div>
                                </form>
                            </div>
                        </Fade>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}