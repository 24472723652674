import React from 'react';

import loaderImg from '../resources/images/loader.svg';

const Loader = () => (
    <div>
        <div className="container">
            <div class="loader"><img src={loaderImg} alt="" /></div>
        </div>
    </div>
)

export default Loader;

