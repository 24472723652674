import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Address from './components/Address';
import Loader from 'components/Loader';
import CheckoutProduct from './components/CheckoutProduct';
import PaymentOptions from './components/PaymentOptions';

import { getAddress, addAddress, getAddressById, getAddressByIdReset } from "store/address/actions";

export default function CheckoutPage1() {
    const userState = useSelector((state) => state.user);
    const authState = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const addressState = useSelector((state) => state.address);
    const addressStateById = useSelector((state) => state.address.addressById);
    const [radioChecked, setRadioChecked] = useState(0);
    const [addressModalShow, setAddressModalShow] = useState(false);
    const cartState = useSelector((state) => state.cart);
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [editId, setEditId] = useState(0);

    const [formErrors, setFormErrors] = useState({});

    const [user, setUser] = useState({
        name: '',
        mobile: '',
        address: '',
        pin: '',
        locality: '',
        editId: 0
    });

    function handleChange(e) {
        const { name, value } = e.target;
        let currentFormErrors = formErrors;

        switch (name) {
            case 'name':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Name';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'mobile':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Mobile';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'address':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter address';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'pin':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Pincode';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'locality':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Mobile';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            default:
                break;
        }
        setUser({ ...user, [name]: value });
        setFormErrors(currentFormErrors);
    }

    useEffect(() => {
        dispatch(getAddress(authState.token));
    }, [dispatch]);

    useEffect(() => {
        setUser({
            name: addressStateById?.data?.name,
            mobile: addressStateById?.data?.phone,
            address: addressStateById?.data?.address,
            pin: addressStateById?.data?.postal_code,
            locality: addressStateById?.data?.locality,
            editId: editId
        });
    }, [addressStateById]);

    function handleChecked(index, id) {
        setRadioChecked(index);
        setEditId(id);
    }

    function handleAddressModalShow() {
        setAddressModalShow(true);
        setUser({
            name: '',
            mobile: '',
            address: '',
            pin: '',
            locality: '',
            editId: 0
        })
        setFormErrors({
            name: '',
            mobile: '',
            address: '',
            pin: '',
            locality: '',
        });
    }

    function handleAddressModalClose() {
        setAddressModalShow(false);
    }

    async function handleAddressEdit(id) {
        if (id) {
            dispatch(getAddressById({ id, token: authState.token })).then(response => {
                setFormErrors({});
                setEditId(id);
                setAddressModalShow(true);
            });
        }
    }

    const addressFormSubmit = async (e) => {
        e.preventDefault();
        const paramId = user.editId
        const payload = {
            name: user.name,
            phone: user.mobile,
            postal_code: user.pin,
            locality: user.locality,
            address: user.address,
        }
        dispatch(addAddress(payload, paramId, authState.token));
        setAddressModalShow(false);
    }

    function handlePaymentShow() {
        setShowPaymentOptions(true);
    }

    function handleShippingShow() {
        setShowPaymentOptions(false);
    }

    return (
        <div>
            {addressState.loading ? <Loader /> : ''}
            <Header />
            <main>
                {/* checkout Sec */}
                <div className="container">
                    <div className="checkout-sec">
                        {/* checkout Left */}
                        {!showPaymentOptions ?
                            <div className="checkout-left">
                                <ul className="contact-ship mb-4">
                                    <li>
                                        <span>Contact</span>
                                        {userState?.email}
                                    </li>
                                </ul>
                                <div className>
                                    {/* Delivery Sec */}
                                    <div className="delivery-sec">
                                        <h3 className="mb-3">Delivery Address</h3>
                                        <ul className="same-diff-ad delevery-list">
                                            {
                                                addressState.address.data?.length ? addressState.address.data?.map((item, index) => (
                                                    <li key={item.id} className="active">
                                                        <input onClick={() => handleChecked(index, item.id)} type="radio" name="radio" value={item.id} className="custom_radio" id={`radio-${item.id}`} checked={`${index == radioChecked ? 'checked' : ''}`} />
                                                        <label htmlFor={`radio-${item.id}`}>
                                                            <strong>{item.name} <span>{item.phone}</span></strong>
                                                            <p>{`${item.address}, ${item.locality}, ${item.postal_code}`}</p>
                                                            {
                                                                index === radioChecked ?
                                                                    <div className>
                                                                        <button className="button small mt-2" onClick={handlePaymentShow}>Deliver Here</button>
                                                                    </div> : ''
                                                            }
                                                        </label>
                                                        {
                                                            index === radioChecked ?
                                                                <button onClick={() => handleAddressEdit(item.id)} className="button border xsmall edit">Edit</button> : ''

                                                        }
                                                    </li>)) : ''
                                            }
                                        </ul>
                                    </div>
                                    {/* Add A New Address Button */}
                                    <div className="mt-3">
                                        <button className="button" onClick={handleAddressModalShow}>+ Add A New Address</button>
                                    </div>
                                    {/* <div className="btn-outer">
                                        <button type="submit" className="button">Continue</button>
                                        <button type="button" className="button border">Cancel</button>
                                    </div> */}
                                </div>

                            </div>
                            :
                            <PaymentOptions
                                handleShippingShow={handleShippingShow}
                                userState={userState}
                            />
                        }
                        {/* checkout Right */}
                        <CheckoutProduct
                            cartState={cartState}
                        />
                    </div>
                </div >
            </main >
            <Address
                modalShow={addressModalShow}
                handleClose={handleAddressModalClose}
                addressFormSubmit={addressFormSubmit}
                formErrors={formErrors}
                handleChange={handleChange}
                user={user}
            />
            <Footer />
        </div >
    );
}