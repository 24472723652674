import React from "react";
import { useNavigate } from 'react-router-dom';

export default function ResultCard(props) {
    const navigate = useNavigate();
    return (
        <div>
            <div className="popup-top maxWidth700 text-center">
                <h3 className="heading text-center">
                    {props.resultData.name}
                </h3>
                <p>
                    <span className="green">Congratulatons!</span> You got {props.resultData.name}.<br />
                    {props.resultData.quiz_description}
                </p>
                <div className="buttons">
                    <button onClick={() => navigate(`/products/${props.resultData.slug}`)} className="button">Take me to all personas</button>
                    <div className>
                        <a onClick={() => navigate('/themes')} className="take-button">TAKE ME TO ALL THEMES</a>
                    </div>
                </div>
            </div>
        </div>
    );
}