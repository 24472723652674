import {
    GET_ADDRESS_BEGIN,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAIL,

    GET_ADDRESSBYID_BEGIN,
    GET_ADDRESSBYID_SUCCESS,
    GET_ADDRESSBYID_FAIL,

    ADD_ADDRESS_BEGIN,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAIL,

    GET_ADDRESSBYID_RESET
} from './types';

const INITIAL_STATE = {
    address: [],
    addressById: [],
    loading: false,
    error: null,
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case GET_ADDRESS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                address: payload,
            }
        case GET_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case GET_ADDRESSBYID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_ADDRESSBYID_SUCCESS:
            return {
                ...state,
                loading: false,
                addressById: payload,
            }
        case GET_ADDRESSBYID_RESET:
            return {
                ...state,
                loading: false,
                addressById: [],
            }
        case GET_ADDRESSBYID_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case ADD_ADDRESS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                address: payload,
            }
        case ADD_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
};