import api from 'services/api';
import {
    GET_CASESTUDY_BEGIN,
    GET_CASESTUDY_SUCCESS,
    GET_CASESTUDY_FAIL,
} from "./types";

export const getCaseStudy = () => async (dispatch) => {
    try {
        dispatch({ type: GET_CASESTUDY_BEGIN });

        const casestudy = await api.get('casestudy');

        dispatch({ type: GET_CASESTUDY_SUCCESS, payload: casestudy })
    } catch (err) {
        dispatch({ type: GET_CASESTUDY_FAIL, payload: err.message });
    }
}
