import {
    GET_CASESTUDY_BEGIN,
    GET_CASESTUDY_SUCCESS,
    GET_CASESTUDY_FAIL
} from './types';

const INITIAL_STATE = {
    casestudy: [],
    loading: false,
    error: null,
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case GET_CASESTUDY_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_CASESTUDY_SUCCESS:
            return {
                ...state,
                loading: false,
                casestudy: payload,
            }
        case GET_CASESTUDY_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
};