import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';

import CCIcon from '../../resources/images/cc-icon.png';
import PayPal from '../../resources/images/paypal.png';
import Gpay from '../../resources/images/gpay-n.png';
import ShopPay from '../../resources/images/shop-pay-n.png';
import OrderIcon from '../../resources/images/order-icon1.png';


export default function CheckoutPage3() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <main>
                {/* checkout Sec */}
                <div className="container">
                    <div className="checkout-sec">
                        {/* checkout Left */}
                        <div className="checkout-left">
                            <ul className="contact-ship">
                                <li>
                                    <span>Contact</span>
                                    admin@gmail.com
                                    <button className="button border xsmall">Change</button>
                                </li>
                                <li>
                                    <span>Ship to</span>
                                    N‑69, Connaught Place, New Delhi, Delhi, 110001
                                    <button className="button border xsmall">Change</button>
                                </li>
                            </ul>
                            {/* Payment Sec */}
                            <div className="payment-sec">
                                <div className="head">
                                    <h3>Payment</h3>
                                    <p>All transection are secured and encrypted</p>
                                </div>
                                <ul className="same-diff-ad">
                                    <li>
                                        <div className="cc-icon-outer">
                                            <input type="radio" name="radio" className="custom_radio" id="radio-1" defaultChecked />
                                            <label htmlFor="radio-1">Credit Card</label>
                                            <div className="cc-icon"><img src={CCIcon} alt="" /><small>and more...</small></div>
                                        </div>
                                        {/* credit card info */}
                                        <div className="cc-info">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group mb-4">
                                                        <label>Card number <span className="error-star">*</span></label>
                                                        <input type="text" name="card number" className="form-control" />
                                                        <p className="error">Please Enter Card Number</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group mb-4">
                                                        <label>Name on card <span className="error-star">*</span></label>
                                                        <input type="text" name="name" className="form-control" />
                                                        <p className="error">Please Enter Name on card</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group mb-4">
                                                        <label>Expiration date (MM/YY)</label>
                                                        <input type="text" name="expiration date" className="form-control" />
                                                        <p className="error">Expiration date (MM/YY)</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group mb-4">
                                                        <label>Security code <span className="error-star">*</span></label>
                                                        <input type="text" name="security code" className="form-control" />
                                                        <p className="error">Please Enter Security Code</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <input type="radio" name="radio" className="custom_radio" id="radio-2" />
                                        <label htmlFor="radio-2"><img src={ShopPay} alt="" /></label>
                                    </li>
                                    <li>
                                        <input type="radio" name="radio" className="custom_radio" id="radio-3" />
                                        <label htmlFor="radio-3"><img src={PayPal} alt="" /></label>
                                    </li>
                                    <li>
                                        <input type="radio" name="radio" className="custom_radio" id="radio-4" />
                                        <label htmlFor="radio-4"><img src={Gpay} alt="" /></label>
                                    </li>
                                </ul>
                            </div>
                            {/* Billing Sec */}
                            <div className="billing-address">
                                <div className="head">
                                    <h3>Billing address</h3>
                                    <p>Select the address that matches your card</p>
                                </div>
                                <ul className="same-diff-ad">
                                    <li>
                                        <input type="radio" name="radio" className="custom_radio" id="radio-5" />
                                        <label htmlFor="radio-5">Same as shipping address</label>
                                    </li>
                                    <li>
                                        <input type="radio" name="radio" className="custom_radio" id="radio-6" />
                                        <label htmlFor="radio-6">Use different billing address</label>
                                    </li>
                                </ul>
                            </div>
                            {/* shipping address */}
                            <div className="shipping-info">
                                <form>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group mb-4">
                                                <label>Country/region </label>
                                                <select className="form-select form-control" aria-label="Country/region">
                                                    <option selected>Country1</option>
                                                    <option value={1}>Country2</option>
                                                    <option value={2}>Country3</option>
                                                    <option value={3}>Country4</option>
                                                </select>
                                                <p className="error">Please Select Country/region</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group mb-4">
                                                <label>First Name <span className="error-star">*</span></label>
                                                <input type="text" name="first name" className="form-control" />
                                                <p className="error">Please Enter First Name</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group mb-4">
                                                <label>Last Name <span className="error-star">*</span></label>
                                                <input type="text" name="last name" className="form-control" />
                                                <p className="error">Please Enter Last Name</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-4">
                                                <label>Address <span className="error-star">*</span></label>
                                                <textarea name="address" className="form-control" defaultValue={""} />
                                                <p className="error">Please Enter Address</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-4">
                                                <label>Apartment, suite, etc</label>
                                                <input type="text" name="apartment" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group mb-4">
                                                <label>City <span className="error-star">*</span></label>
                                                <input type="text" name="city" className="form-control" />
                                                <p className="error">Please Enter City</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group mb-4">
                                                <label>Postalcode <span className="error-star">*</span></label>
                                                <input type="text" name="postalcode" className="form-control" />
                                                <p className="error">Please Enter Postalcode</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-outer">
                                        <button type="submit" className="button">Pay now</button>
                                        <button type="button" className="button border" onClick={() => navigate('/checkout-page2')}>Return to Shipping</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* checkout Right */}
                        <div className="checkout-right">
                            <ul className="order-list">
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming </strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        9
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                </li>
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming is the discipline of deciding</strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        6
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                </li>
                            </ul>
                            <table className="table border-none">
                                <tbody><tr>
                                    <td>30 min consultation - Gaping Void</td>
                                    <td className="text-center" />
                                    <td className="text-right"><span>Complimentary</span></td>
                                </tr>
                                </tbody></table>
                            <div className="input-outer">
                                <input type="text" placeholder="Gift card or discount code" defaultValue className="subscribe-input" />
                                <button type="submit" className="submit">Apply</button>
                            </div>
                            <ul className="subtotal">
                                <li>
                                    <div className="text-left">Subtotal</div>
                                    <div className="text-right">$1980</div>
                                </li>
                                <li>
                                    <div className="text-left">Shipping</div>
                                    <div className="text-right"><div className="green">Free</div></div>
                                </li>
                                <li>
                                    <div className="black">Total</div>
                                    <div className="black text-right">$1999</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

// const mapDispatch = { login };

// export default connect(null, mapDispatch)(Themes);