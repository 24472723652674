import api from 'services/api';
import {
    GET_CATEGORY_BEGIN,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
} from "./types";

export const getCategory = () => async (dispatch) => {
    try {
        dispatch({ type: GET_CATEGORY_BEGIN });

        const category = await api.get('categories');

        dispatch({ type: GET_CATEGORY_SUCCESS, payload: category })
    } catch (err) {
        dispatch({ type: GET_CATEGORY_FAIL, payload: err.message });
    }
}
