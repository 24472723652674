import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import parse from 'html-react-parser';

import { getProducts } from "store/products/actions";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from 'components/Loader';

export const Products = () => {
    let { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const products = useSelector((state) => state.products);

    useEffect(() => {
        dispatch(getProducts(slug));
    }, [slug, dispatch]);

    return (
        <div>
            <Header />
            {products.products.loading ? (
                // <div className="App text-center pt-5">
                //     <span className="h3 ">Loading...</span>
                // </div>
                <Loader />
            ) : (
                <main>
                    <div className="container">
                        <Fade bottom>
                            <div className="product-top-box">
                                <h3>{products.products.data?.name}</h3>
                                <p>{products.products.data?.description}</p>
                                <button className="button">VIEW TILES</button>
                            </div>
                        </Fade>
                        <div className="pro">
                            <h3 className="text-center">
                                {products.products.data?.help_heading}
                            </h3>
                            <ul className="pro-list">
                                {
                                    products.products.loading ? (
                                        <div className="App text-center pt-5">
                                            <span className="h3 ">Loading...</span>
                                        </div>
                                    ) :
                                        (products.products.data?.help_data?.length ? products.products.data.help_data?.map(item => (
                                            <Fade bottom>
                                                <li>
                                                    <div className="img">
                                                        <img src={`${products.products.metadata.image_url}${item.image_name}?version=${item.updated_at}`} alt="" />
                                                    </div>
                                                    <p>{item.description}</p>
                                                </li>
                                            </Fade>
                                        )) : (
                                            <div className="col-sm-12 text-center pt-5">
                                                <span className="h3 ">No data found</span>
                                            </div>
                                        )
                                        )
                                }
                            </ul>
                        </div>
                        <Fade bottom>
                            <div className="head">
                                <h3 className="heading text-center">{products.products.data?.product_heading}</h3>
                                <p>{parse(products?.products?.data?.product_description ? products.products.data.product_description : 'No description')}</p>
                            </div>
                        </Fade>
                        <ul className="product-list">
                            {
                                products.products.loading ? (
                                    <div className="App text-center pt-5">
                                        <span className="h3 ">Loading...</span>
                                    </div>
                                ) :
                                    (products.products.data?.products?.length ? products.products.data.products?.map(item => (
                                        <Fade bottom>
                                            <li key={item.id}>
                                                <div className="img">
                                                    <img src={`${products.products.metadata.product_image_url}${item.image_name}?version=${item.updated_at}`} alt="" />
                                                    <em className="right-corner" />
                                                </div>
                                                <div className="product-hover">
                                                    <h4>{item.name}</h4>
                                                    <p>{item.short_description} </p>
                                                    <a onClick={() => navigate(`/product/detail/${item.slug}`)} className="button">VIEW TILES</a>
                                                </div>
                                            </li>
                                        </Fade>
                                    )) : (
                                        <div className="col-sm-12 text-center pt-5">
                                            <span className="h3 ">No data found</span>
                                        </div>
                                    )
                                    )
                            }
                        </ul>
                    </div>
                </main>)}
            <Footer />
        </div>
    );
}
export default Products;