import React, { useState } from 'react';

import CCIcon from '../../../resources/images/cc-icon.png';
import PayPal from '../../../resources/images/paypal.png';
import Gpay from '../../../resources/images/gpay-n.png';
import ShopPay from '../../../resources/images/shop-pay-n.png';
import OrderIcon from '../../../resources/images/order-icon1.png';

export default function PaymentOptions(props) {
    return (
        <>
            <div className="checkout-left">
                <ul className="contact-ship">
                    <li>
                        <span>Contact</span>
                        {props.userState?.email}
                        {/* <button className="button border xsmall">Change</button> */}
                    </li>
                    <li>
                        <span>Ship to</span>
                        N‑69, Connaught Place, New Delhi, Delhi, 110001
                        <button className="button border xsmall" onClick={props.handleShippingShow}>Change</button>
                    </li>
                </ul>
                {/* Payment Sec */}
                <div className="payment-sec">
                    <div className="head">
                        <h3>Payment</h3>
                        <p>All transection are secured and encrypted</p>
                    </div>
                    <ul className="same-diff-ad">
                        <li>
                            <div className="cc-icon-outer">
                                <input type="radio" name="radio" className="custom_radio" id="radio-1" defaultChecked />
                                <label htmlFor="radio-1">Credit Card</label>
                                <div className="cc-icon"><img src={CCIcon} alt="" /><small>and more...</small></div>
                            </div>
                        </li>
                        <li>
                            <input type="radio" name="radio" className="custom_radio" id="radio-2" />
                            <label htmlFor="radio-2"><img src={ShopPay} alt="" /></label>
                        </li>
                        <li>
                            <input type="radio" name="radio" className="custom_radio" id="radio-3" />
                            <label htmlFor="radio-3"><img src={PayPal} alt="" /></label>
                        </li>
                        <li>
                            <input type="radio" name="radio" className="custom_radio" id="radio-4" />
                            <label htmlFor="radio-4"><img src={Gpay} alt="" /></label>
                        </li>
                    </ul>
                    <div class="btn-outer">
                    <button type="submit" className="button">Place order & Pay</button>
                    <button type="button" className="button border">Return to Shipping</button>
                  </div>
                </div>
            </div>
        </>
    );
}
