import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

import api from 'services/api';
import { showMessage, validEmail } from 'services/utility';

export default function Subscribe(props) {
    const [apiError, setApiError] = useState('');
    const [postData, setPostData] = useState({
        email: '',
    });
    const [formErrors, setFormErrors] = useState({
        email: '',
    });

    function handleChange(e) {
        const { name, value } = e.target;
        let currentFormErrors = formErrors;

        switch (name) {
            case 'email':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Email';
                } else if (validEmail(value)) {
                    currentFormErrors[name] = 'Please Enter a valid email';
                }
                else {
                    delete currentFormErrors[name];
                }
                break;
            default:
                break;
        }
        setPostData({ ...postData, [name]: value });
        setFormErrors(currentFormErrors);
    }

    const subscribeSubmit = async (e) => {
        try {
            e.preventDefault();

            const result = await api.post('subscribe', {email: postData.email});

            if (result && result.code == 200) {
                showMessage('success', result.message);
            } else {
                showMessage('error', result.message);
            }
        } catch (err) {
            showMessage('error', err.message);
        }
    }

    return (
        <>
            <section className="subscriber-outer">
                <div className="container">
                    <Fade top>
                        <div className="subscriber-inner">
                            <h3 className="heading">
                                Get free insights &amp; inspiration three times a week!
                            </h3>
                            <form onSubmit={subscribeSubmit}>
                                <div className="input-outer">
                                    <input
                                        type="text"
                                        name="email"
                                        className="subscribe-input"
                                        noValidate
                                        onChange={handleChange}
                                    />
                                    <button type="submit" className={`submit ${Object.entries(formErrors || {}).length > 0 ? 'disabled' : ''}`} disabled={Object.entries(formErrors || {}).length > 0}>Go</button>
                                    {formErrors && formErrors.email ? (<p className="error">{formErrors.email}</p>) : ''}
                                    <p className="info">We value your privacy and won’t share your information with anyone</p>
                                </div>
                            </form>
                        </div>
                    </Fade>
                </div>
            </section>
        </>
    );
}
