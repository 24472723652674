import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';

import OrderIcon from '../../resources/images/order-icon1.png';

export default function CheckoutPage2() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <main>
                {/* checkout Sec */}
                <div className="container">
                    <div className="checkout-sec">
                        {/* checkout Left */}
                        <div className="checkout-left">
                            <ul className="contact-ship">
                                <li>
                                    <span>Contact</span>
                                    admin@gmail.com
                                    <button className="button border xsmall">Change</button>
                                </li>
                                <li>
                                    <span>Ship to</span>
                                    N‑69, Connaught Place, New Delhi, Delhi, 110001
                                    <button className="button border xsmall">Change</button>
                                </li>
                            </ul>
                            <div className="btn-outer">
                                <button type="submit" className="button" onClick={() => navigate('/checkout-page3')}>Continue to payment</button>
                                <button type="button" className="button border" onClick={() => navigate('/checkout-page1')}>Return to Information</button>
                            </div>
                        </div>
                        {/* checkout Right */}
                        <div className="checkout-right">
                            <ul className="order-list">
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming </strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        9
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                </li>
                                <li>
                                    <div className="ord-name">
                                        <div className="img"><img src={OrderIcon} alt="" /></div>
                                        <strong>Product naming is the discipline of deciding</strong>
                                    </div>
                                    <div className="ord-frames">
                                        <strong>Frames</strong>
                                        6
                                    </div>
                                    <div className="ord-price">
                                        <strong>Price</strong>
                                        $1999
                                    </div>
                                </li>
                            </ul>
                            <table className="table border-none">
                                <tbody><tr>
                                    <td>30 min consultation - Gaping Void</td>
                                    <td className="text-center" />
                                    <td className="text-right"><span>Complimentary</span></td>
                                </tr>
                                </tbody></table>
                            <div className="input-outer">
                                <input type="text" placeholder="Gift card or discount code" defaultValue className="subscribe-input" />
                                <button type="submit" className="submit">Apply</button>
                            </div>
                            <ul className="subtotal">
                                <li>
                                    <div className="text-left">Subtotal</div>
                                    <div className="text-right">$1980</div>
                                </li>
                                <li>
                                    <div className="text-left">Shipping</div>
                                    <div className="text-right"><div className="green">Free</div></div>
                                </li>
                                <li>
                                    <div className="black">Total</div>
                                    <div className="black text-right">$1999</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

// const mapDispatch = { login };

// export default connect(null, mapDispatch)(Themes);