import React from 'react';

export default function ResetPassowrd(props) {

    return (
        <>
            <div className={`popup-outer small ${props.modalShow ? 'show' : ''}`}>
                <div className="popup-overlay" />
                <div className="popup-sec">
                    <div className="popup-close" onClick={props.handleClose} />
                    {/* Reset Password */}
                    <div className="or-text"><strong>Reset Password</strong></div>
                    <form className="forgot-password">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>Old Password <span className="error-star">*</span></label>
                                    <input type="text" className="form-control" />
                                    <p className="error">Please Enter Old Password</p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>New Password <span className="error-star">*</span></label>
                                    <input type="text" className="form-control" />
                                    <p className="error">Please Enter New Password</p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group mb-4">
                                    <label>Confirm New Password <span className="error-star">*</span></label>
                                    <input type="text" className="form-control" />
                                    <p className="error">Please Enter Confirm New Password</p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="fbtn-outer text-center">
                                    <button type="submit" className="button">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
