import products from "store/products";
import {
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    GET_CART_BEGIN,
    GET_CART_SUCCESS,
    GET_CART_FAIL,
    ADD_CARTTODB_BEGIN,
    ADD_CARTTODB_SUCCESS,
    ADD_CARTTODB_FAIL
} from "./types";

const INITIAL_STATE = {
    products: [],
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case ADD_PRODUCT: {
            // if (state.products && state.products?.data?.length) {
            //     state.products['data'] = state.products.data.concate(payload)
            // } else {
            // state.products['data'] = payload
            // }
            let currentProdData = state.products;
            var haveAdd = true;
            console.log(payload[0].product_id, 'ss')
            for (var k in currentProdData) {
                if (currentProdData[k]['product_id'] === payload[0].product_id && currentProdData[k]['option_id'] === payload[0].option_id) {
                    // alert('coin')
                    haveAdd = false;
                    break;
                }
            }
            return {
                ...state,
                products: haveAdd ? state.products.concat(payload) : state.products
            };
        }
        case REMOVE_PRODUCT: {
            // alert(payload.prodId)
            // alert(payload.opId)
            // console.log(state.products.data, 'yave reddy')
            // // state.products['data'] = state.products?.data?.filter(p => { return (p.product_id !== payload.prodId && p.option_id !== payload.opId) });
            // state.products['data'] = state.products?.data?.filter(function (p) {
            //     if (p.product_id !== payload.prodId && p.option_id !== payload.opId) {
            //         return true;
            //     }
            // });
            console.log(payload.opId, 'wswsw')
            var indexToDel = -1;
            state.products.forEach(function (item, index) {
                if (item.product_id === payload.prodId && item.option_id === payload.opId) {
                    indexToDel = index;
                }
            });
            return {
                ...state,
                // products: state.products.filter((update) => {
                //     return update.option_id !== payload.opId
                // })
                products: state.products.filter((data, idx) => idx !== indexToDel)
            };
        }
        case GET_CART_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                products: payload.data,
                metadata: payload.metadata
            }
        case GET_CART_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case ADD_CARTTODB_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case ADD_CARTTODB_SUCCESS:
            return {
                ...state,
                loading: false,
                products: payload.data,
                metadata: payload.metadata

            }
        case ADD_CARTTODB_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
};