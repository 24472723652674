import api from 'services/api';
import {
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    GET_CART_BEGIN,
    GET_CART_SUCCESS,
    GET_CART_FAIL,
    ADD_CARTTODB_BEGIN,
    ADD_CARTTODB_SUCCESS,
    ADD_CARTTODB_FAIL
} from "./types";

import { handleResponse } from 'store/common/actions';

export const addProduct = (payload) => (dispatch) => {
    dispatch({ type: ADD_PRODUCT, payload });
};

export const removeProduct = (payload) => (dispatch) => {
    dispatch({ type: REMOVE_PRODUCT, payload });
};

export const getCart = (token) => async (dispatch) => {
    try {
        dispatch({ type: GET_CART_BEGIN });

        const products = await api.get('cart', {}, {
            headers: {
                'token': token,
            }
        });

        dispatch(handleResponse(products));

        dispatch({ type: GET_CART_SUCCESS, payload: products })
    } catch (err) {
        dispatch({ type: GET_CART_FAIL, payload: err.message });
    }
}

export const addProductToDB = (payload, token) => async (dispatch) => {
    try {
        dispatch({ type: ADD_CARTTODB_BEGIN });

        const result = await api.post('cart', payload, {
            headers: {
                'token': token,
            }
        });

        dispatch(handleResponse(result));

        dispatch({ type: ADD_CARTTODB_SUCCESS, payload: result })
    } catch (err) {
        dispatch({ type: ADD_CARTTODB_FAIL, payload: err.message });
    }
}
