import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { getCart, removeProduct } from "store/cart/actions";

import api from 'services/api';
import { showMessage } from 'services/utility';
import { logout } from "store/auth/actions";

import Cart from 'components/Cart'
import Login from 'components/Login'
import Signup from 'components/Signup'
import ForgotPassword from 'components/ForgotPassword'

import Logo from '../resources/images/logo.svg';
import UserIcon from '../resources/images/user-icon.png';
import ResetPassowrd from './ResetPassword';
import Loader from './Loader';

export default function Header() {
    const [modalLogin, setLoginModal] = useState(false);
    const [modalSignup, setModalSignup] = useState(false);
    const [modalCart, setModalCart] = useState(false);
    const [localLoader, setLocalLoader] = useState(false);

    const authData = useSelector((state) => state.auth);
    const userData = useSelector((state) => state.user);
    const cartState = useSelector((state) => state.cart.products);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [showMsgFlag, setShowMsgFlag] = useState(false);
    const [cartTotal, setCartTotal] = useState("0.00");

    useEffect(() => {
        if (cartState?.length) {
            calculateTotal();
            showMsgOption();
        }
    }, [cartState]);

    // state = {
    //     modalShow: false,
    //     modalLogin: false,
    //     modalSignup: false,
    //     modalForgotPassword: false,
    //     modalResetPassword: false,
    //     isLoggedIn: false
    // }

    function handleCartModal() {
        setModalCart(true);
    }

    function handleCartModalClose() {
        setModalCart(false);
    }

    function handleLoginModal() {
        setModalSignup(false);
        setLoginModal(true);
    }

    function handleLoginModalClose() {
        setLoginModal(false);
    }

    function handleSignupModal() {
        // this.setState({ modalForgotPassword: false })
        setLoginModal(false);
        setModalSignup(true);
    }

    function handleSignupModalClose() {
        setModalSignup(false);
    }

    function handleForgotPasswordModal(e) {
        // e.preventDefault();
        // this.setState({ modalLogin: false })
        // this.setState({ modalForgotPassword: true })
    }

    function handleForgotPasswordClose() {
        // this.setState({ modalForgotPassword: false })
    }

    function handleResetPasswordModal(e) {
        // e.preventDefault();
        // this.setState({ modalResetPassword: true })
    }

    function handleResetPasswordClose() {
        // this.setState({ modalResetPassword: false })
    }

    function handleLogin(e) {
        // e.preventDefault();
        // this.setState({ modalLogin: false })
        // this.setState({ isLoggedIn: true })
    }

    async function handleLogout(e) {
        try {
            e.preventDefault();

            const result = await api.get('logout', {}, {
                headers: {
                    'token': authData.token,
                }
            });

            if (result && result.code == 200) {
                dispatch(logout());
                navigate('/')
            } else {
                showMessage('error', result.message);
            }
        } catch (err) {
            showMessage('error', err.message);
        }
    }

    function calculateTotal() {
        if (cartState?.length) {
            let sum = cartState.reduce(function (prev, current) {
                return prev + +current.price
            }, 0);
            setCartTotal(sum);
        }
    }

    function showMsgOption() {
        if (cartState?.length) {
            for (var property in cartState) {
                if (cartState[property]['show_msg'] === 1) {
                    setShowMsgFlag(true);
                    // return;
                } else {
                    setShowMsgFlag(false);
                }
            }
        }
    }

    async function handleDeleteCart(id, prodId, optionId) {
        try {
            setLocalLoader(true);
            if (authData.isAuthenticated) {
                const result = await api.delete(`cart/${id}`, {},
                    {
                        headers: {
                            'token': authData.token,
                        }
                    }
                );

                if (result && result.code == 200) {
                    setLocalLoader(false);
                    dispatch(getCart(authData.token));
                } else {
                    setLocalLoader(false);
                    showMessage('error', result.message);
                }
            } else {
                dispatch(removeProduct({ prodId, opId: optionId }));
                setLocalLoader(false);
            }
            calculateTotal();
            showMsgOption();
        } catch (err) {
            setLocalLoader(false);
            showMessage('error', err.message);
        }
    }

    function handleCheckout() {
        if (authData.isAuthenticated) {
            navigate('/checkout-page1')
        } else {
            setModalCart(false);
            setLoginModal(true);
        }

    }

    // render() {
    // const { userName, cartItems } = this.props;
    // const { text } = this.state;
    return (
        <div>
            {localLoader ? <Loader /> : ''}
            <header>
                <div className="container">
                    <div className="header-inner">
                        <Link className="active" to="/"><img src={Logo} alt="" /></Link>
                        <div className="header-right">
                            <div className="menu-icon">
                                <span className="bar1" />
                                <span className="bar2" />
                                <span className="bar3" />
                            </div>
                            <div className="mob-nav-overlay" />
                            <nav>
                                <ul className="menu">
                                    <li>
                                        <Link className="active" to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/themes">Themes</Link>
                                    </li>
                                    <li>
                                        <Link to="/how-it-works">How it works</Link>
                                    </li>
                                    <li>
                                        <a href="#">Culture Science</a>
                                    </li>
                                    <li>
                                        <Link to="/ceo-culture">Case Study</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </nav>
                            {/* Login Outer */}
                            <div className="login-cart">
                                {/* Header Cart */}
                                <div className="cart-icon" onClick={handleCartModal}>
                                    <div className="cart-val">{cartState?.length ? cartState.length : 0}</div>
                                </div>
                                {/* Login */}
                                <a className={`button border small login ${authData?.isAuthenticated ? 'hide' : ''}`} onClick={handleLoginModal} >Sign In</a>
                                {/* User Profile */}
                                <div className={`user-profile ${authData?.isAuthenticated ? '' : 'hide'}`} >
                                    <div className="user-img">
                                        <img src={UserIcon} alt="user icon" />
                                    </div>
                                    <p>{userData?.name}</p>
                                    <ul className="sub-menu">
                                        <li><Link to="/profile/order">Profile</Link></li>
                                        <li><a onClick={handleResetPasswordModal}>Reset Password</a></li>
                                        <li><button className="button small" onClick={handleLogout}>Log out</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Cart
                handleClose={handleCartModalClose}
                modalShow={modalCart}
                handleDelete={handleDeleteCart}
                cartState={cartState}
                showMessage={showMsgFlag}
                cartTotal={cartTotal}
                handleCheckout={handleCheckout}
            />
            <Login
                handleClose={handleLoginModalClose}
                handleSignupModal={handleSignupModal}
                handleForgotPasswordModal={handleForgotPasswordModal}
                modalShow={modalLogin}
                handleLoginText={handleLogin}
                cartState={cartState}
            />
            <Signup
                handleClose={handleSignupModalClose}
                handleLoginModal={handleLoginModal}
                modalShow={modalSignup}
            />
            {/* <ForgotPassword
                handleClose={this.handleForgotPasswordClose}
                handleSignupModal={this.handleSignupModal}
                modalShow={this.state.modalForgotPassword}
            /> */}
            {/* <ResetPassowrd
                handleClose={this.handleResetPasswordClose}
                modalShow={this.state.modalResetPassword}
            /> */}
        </div>
    )
    // }

}

// Header.propTypes = {
//     // user: PropTypes.string,
//     // cartItems: PropTypes.number,
//     // onSearch: PropTypes.func,
// }

// Header.defaultTypes = {
//     // user: '',
//     // cartItems: 0
// }

// export default Header;

