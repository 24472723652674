import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

import api from 'services/api';
import { showMessage } from 'services/utility';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TestimonialInnerSlider from './components/TestimonialInnerSlider'
import TestimonialRightSlider from './components/TestimonialRightSlider'
import Quiz from './components/Quiz';
import Loader from 'components/Loader';
import Subscribe from 'components/Subscribe';

import { getQuiz } from 'store/quiz/actions';

import LRImg1 from '../../resources/images/l-r-img1.png';
import LRImg2 from '../../resources/images/l-r-img2.png';
import LRImg3 from '../../resources/images/l-r-img3.png';
import Brand from '../../resources/images/brand.svg';
import MicrosoftIcon from '../../resources/images/microsoft-icon.svg';
import BannerRight from '../../resources/images/banner-right.png';
import CultureImg1 from '../../resources/images/thumbnail-1.svg';
import CultureImg2 from '../../resources/images/thumbnail-2.svg';
import CultureImg3 from '../../resources/images/thumbnail-3.svg';
import CultureImg4 from '../../resources/images/thumbnail-4.svg';

export default function Home() {
    const dispatch = useDispatch()
    const [showQuiz, setShowQuiz] = useState(false);
    const quizState = useSelector((state) => state.quiz);
    const [showResultMst, setShowResultMst] = useState(false);
    const [homeData, setHomeData] = useState({});

    function handleQuizPopup() {
        setShowResultMst(false);
        dispatch(getQuiz()).then(response => {
            setShowQuiz(true);
        });
    }

    useEffect(() => {
        async function fetchData() {
            try {
                // setApiError('');
                const result = await api.get('home');

                if (result && result.code == 200) {
                    setHomeData(result);
                } else {
                    // setApiError(result.message);
                }
            } catch (err) {
                showMessage('error', err.message);
            }
        }
        fetchData();
    }, [])

    function handleQuizClose() {
        setShowQuiz(false);
    }

    return (
        <div>
            {quizState.loading ? <Loader /> : ''}
            <Header />
            <main>
                {/* Banner */}
                <section className="banner">
                    <div className="container">
                        <div className="banner-inner">
                            <Fade left>
                                <div className="banner-left">
                                    <h1>Breathe life into
                                        your workplace</h1>
                                    <p>Culture Walls® are simple and effective tools for scaling and integrating cultural norms. </p>
                                    {/* <button className="button">Comission my culture wall</button> */}
                                    <Link to="/themes" className="button">Comission my culture wall</Link>
                                    <div className="microsoft">
                                        <span>Helping shape the work culture at <img src={MicrosoftIcon} alt="" /></span>
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="banner-right">
                                    <img src={BannerRight} alt="" />
                                    <div className="banner-r-text">
                                        <h2>
                                            <span>find out my</span>
                                            culture personality
                                            <small>In 10 simple steps</small>
                                        </h2>
                                        <button className="button quiz" onClick={handleQuizPopup}>Quiz me!</button>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </section>
                {/* Culture Sec */}
                <section className="culture-outer">
                    <div className="container">
                        <h3 className="heading">
                            What are <br />Culture Walls®?
                        </h3>
                        <p className="multi-color-heading">
                            Culture Walls® are a collection of <span className="blue">beliefs, </span><span className="green">mindsets, </span><span className="orange">principles</span> and <span className="sky">world views</span> that when taken together informs the collective behavior of your organization.
                        </p>
                        <div className="culture-inner">
                            <ul className="culture-list">
                                <Fade left>
                                    <li>
                                        <div className="img">
                                            <img src={CultureImg1} alt="" />
                                        </div>
                                        <div className="culture-list-text">
                                            <h5>Your ideas build your organization</h5>
                                            <p>As a leader, personal success is wholly dependent on influence. Influence is important because it directly affects behavior, how and why people work.</p>
                                        </div>
                                    </li>
                                </Fade>
                                <Fade right>
                                    <li>
                                        <div className="img">
                                        <img src={CultureImg2} alt="" />
                                        </div>
                                        <div className="culture-list-text">
                                            <h5>Sustain efficiency and  agreement</h5>
                                            <p>Sustain efficiency through the collection of beliefs, mindsets, mental models, values, principles, world views, and attitudes that inform behaviors.</p>
                                        </div>
                                    </li>
                                </Fade>
                                <Fade left>
                                    <li>
                                        <div className="img">
                                        <img src={CultureImg3} alt="" />
                                        </div>
                                        <div className="culture-list-text">
                                            <h5>Create a future motivated state</h5>
                                            <p>Create a future motivated state and  impact organizational outcomes by informing culture, mindset, beliefs, and behaviors through semiotic tools.</p>
                                        </div>
                                    </li>
                                </Fade>
                                <Fade right>
                                    <li>
                                        <div className="img">
                                        <img src={CultureImg4} alt="" />
                                        </div>
                                        <div className="culture-list-text">
                                            <h5>Drive operational outcomes</h5>
                                            <p>Drive operational outcomes through deliberately designed semiotics that portrays a nuanced approach to combining picture superiority and emotion.</p>
                                        </div>
                                    </li>
                                </Fade>
                            </ul>
                            </div>
                        <div className="text-center">
                            {/* <button className="button">EXPLORE THEMES</button> */}
                            <Link to="/themes" className="button">EXPLORE THEMES</Link>
                        </div>
                    </div>
                </section>
                {/* Video & Testimonial */}
                <section className="video-testimonial">
                    <div className="container">
                        <Fade top>
                            <div className="head">
                                <h3 className="heading text-center">{homeData?.data?.youtube_title}</h3>
                                <p>{homeData?.data?.youtube_description}</p>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="video-sec">
                                {/* {homeData?.data?.youtube_url} */}
                                <iframe src={homeData?.data?.youtube_url}
                                    frameBorder='0'
                                    allow='autoplay; encrypted-media'
                                    allowFullScreen
                                    title='video'
                                    height={639}
                                    width={1027}
                                />
                            </div>
                        </Fade>
                        <div className="testimonial-sec">
                            <div className="testimonial-inner">
                                <TestimonialInnerSlider
                                    data={homeData}
                                />
                            </div>
                            <div className="testimonial-inner right">
                                <TestimonialRightSlider
                                    data={homeData}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/* Video Testimonial */}
                <Subscribe />
                {/* branding */}
                <section className="branding-outer">
                    <div className="container">\
                        <Fade bottom>
                            <img src={Brand} alt="" />
                        </Fade>
                    </div>
                </section>
                {/* left-right-sec */}
                <section className="left-right-sec">
                    <div className="container">
                        <div className="left-right-inner">
                            <Fade left>
                                <div className="l-r-img">
                                    <img src={LRImg1} alt="" />
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="l-r-text">
                                    <p>First, beliefs inform behaviors. Groups of people who share a common perspective to life, business, people, work together with less friction, collaborate with ease and are connected to a common goal.</p>
                                    <p>They maintain the momentum that the leader initiates.</p>
                                </div>
                            </Fade>
                        </div>
                        <div className="left-right-inner">
                            <Fade right>
                                <div className="l-r-img">
                                    <img src={LRImg2} alt="" />
                                </div>
                            </Fade>
                            <Fade left>
                                <div className="l-r-text">
                                    <p>Semiotics is the study of the signs and symbols that imbue meaning and meaningful communication. It includes the study of signs and sign processes, indication, designation, likeness, analogy, allegory, metonymy, metaphor, symbolism, signification, and communication.</p>
                                </div>
                            </Fade>
                        </div>
                        <div className="left-right-inner">
                            <Fade left>
                                <div className="l-r-img">
                                    <img src={LRImg3} alt="" />
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="l-r-text">
                                    <p>Whatever change is needed, whether it’s a complete culture redesign, strengthening Diversity and Inclusion, creating vibrant innovation, or solidifying digital transformation, Culture Walls  creates alignment between leadership, influence, and business outcomes by turning change into a movement.</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            {showQuiz ? (
                <Quiz
                    showQuiz={showQuiz}
                    handleQuizClose={handleQuizClose}
                    quizState={quizState}
                    showResultMst={showResultMst}
                />) : ''
            }
        </div>
    )
}