import { LOGOUT } from '../auth/types';
import { REMOVE } from '../user/types';
import { RESPONSE_HANDLER } from './types';

export const handleResponse = (response, showSuccessMsg = false) => (dispatch) => {
    if (response.code === 401) {
        dispatch({ type: LOGOUT });
        dispatch({ type: REMOVE });
    } else if (response.code !== 200) {
        //Error dispatch
    }

    if (showSuccessMsg) {
        //Success message dispatch
    }
};