import {
    GET_QUIZ_BEGIN,
    GET_QUIZ_SUCCESS,
    GET_QUIZ_FAIL
} from './types';

const INITIAL_STATE = {
    quiz: [],
    loading: false,
    error: null,
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case GET_QUIZ_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_QUIZ_SUCCESS:
            return {
                ...state,
                loading: false,
                quiz: payload,
            }
        case GET_QUIZ_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
};