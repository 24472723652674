import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import api from 'services/api';
import { showMessage } from 'services/utility';

import { login } from "store/auth/actions";
import { addProductToDB } from "store/cart/actions";

export default function Login(props) {
    const [user, setUser] = useState({
        email: '',
        password: ''
    });
    const [apiError, setApiError] = useState('');

    const [formErrors, setFormErrors] = useState({
        email: '',
        password: ''
    });
    const dispatch = useDispatch()

    function handleChange(e) {
        const { name, value } = e.target;
        let currentFormErrors = formErrors;

        switch (name) {
            case 'email':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Email';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            case 'password':
                if (!value) {
                    currentFormErrors[name] = 'Please Enter Password';
                } else {
                    delete currentFormErrors[name];
                }
                break;
            default:
                break;
        }
        setUser({ ...user, [name]: value });
        setFormErrors(currentFormErrors);
    }

    const loginFormSubmit = async (e) => {
        try {
            e.preventDefault();
            setApiError('');
            const { email, password } = user;

            if (!email) throw new Error('Email can not be empty');
            if (!password) throw new Error('Password can not be empty');

            const result = await api.post('login', { email: user.email, password: user.password, device_id: '12345675888888' });

            if (result && result.code == 200) {
                dispatch(login({ user: result.data }));
                dispatch(addProductToDB(props.cartState, result.data.token));
                props.handleClose()
            } else {
                setApiError(result.message);
            }
        } catch (err) {
            showMessage('error', err.message);
        }
    }


    return (
        <>
            <div className={`popup-outer ${props.modalShow ? 'show' : ''}`} id="Login">
                <div className="popup-overlay" />
                <div className="popup-sec">
                    <div className="popup-close" onClick={props.handleClose} />
                    {/* Login */}
                    <div className="or-text"><strong>Sign In</strong></div>
                    <form className="login-form" onSubmit={loginFormSubmit} noValidate>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <label>Email <span className="error-star">*</span></label>
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        noValidate
                                        onChange={handleChange}
                                    />
                                    {formErrors && formErrors.email ? (<p className="error">{formErrors.email}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4">
                                    <label>Password <span className="error-star">*</span></label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        noValidate
                                        onChange={handleChange}
                                    />
                                    {formErrors && formErrors.password ? (<p className="error">{formErrors.password}</p>) : ''}
                                </div>
                            </div>
                            <div className="col-sm-12 text-right">
                                <button className="green-link" onClick={props.handleForgotPasswordModal}>Forgot Password</button>
                            </div>
                        </div>
                        {apiError ? <p class="error text-center">{apiError}!</p> : ''}
                        <div className="btn-outer">
                            <button type="button" className="button border" onClick={props.handleClose}>Cancel</button>
                            <button type="submit" className={`button ${Object.entries(formErrors || {}).length > 0 ? 'disabled' : ''}`} disabled={Object.entries(formErrors || {}).length > 0}>Sign In</button>
                        </div>
                    </form>
                    {/* Don't have an account? */}
                    <div className="already-signup"><span>Don't have an account? <button onClick={props.handleSignupModal} type="button" className="green-link">Sign Up</button></span></div>
                </div>
            </div>
        </>
    );
}
