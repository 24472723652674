import api from 'services/api';
import {
    GET_PRODUCT_BEGIN,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
} from "./types";

export const getProduct = (slug) => async (dispatch) => {
    try {
        dispatch({ type: GET_PRODUCT_BEGIN });

        const products = await api.get('product', { slug });

        dispatch({ type: GET_PRODUCT_SUCCESS, payload: products })
    } catch (err) {
        dispatch({ type: GET_PRODUCT_FAIL, payload: err.message });
    }
}
