import api from 'services/api';
import {
    GET_QUIZ_BEGIN,
    GET_QUIZ_SUCCESS,
    GET_QUIZ_FAIL,
} from "./types";

export const getQuiz = () => async (dispatch) => {
    try {
        dispatch({ type: GET_QUIZ_BEGIN });

        const category = await api.get('quiz');

        dispatch({ type: GET_QUIZ_SUCCESS, payload: category })
    } catch (err) {
        dispatch({ type: GET_QUIZ_FAIL, payload: err.message });
    }
}
