
export const showMessage = (type = 'success', msg) => {
    return alert(msg);
};

export const truncateString = (string, maxLength = 50) => {
    if (!string) return;
    if (string.length <= maxLength) return string;
    return `${string.substring(0, maxLength)}...`;
};

export function minMaxLength(text, minLength, maxLength) {
    let result = !text || text.length < minLength;
    if (maxLength)
        result = result || text.length < minLength;
    return result;
}

export function validateConfirmPassword(password, confirmpassword) {
    if (password !== confirmpassword) {
        return true;
    }
    return false;
}

export function validEmail(text) {
    const regex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );

    return !regex.test(text);
}


export default {
    showMessage,
    truncateString,
    minMaxLength,
    validEmail,
    validateConfirmPassword
};