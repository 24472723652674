import React from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import './App.css';
import '../src/resources/styles/style.css'
import '../src/resources/fonts/font.css'
import store from "./store";
import Home from './pages/Home';
import Themes from './pages/themes';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import Login from './pages/Login';
import CheckoutPage1 from 'pages/Checkout/checkout1';
import CheckoutPage2 from 'pages/Checkout/checkout2';
import CheckoutPage3 from 'pages/Checkout/checkout3';
import Order from 'pages/Profile/order';
import ManageAddress from 'pages/Profile/manage-address';
import AddAddress from 'pages/Profile/add-address';
import ResetPassowrd from 'components/ResetPassword';
import CEOCulture from 'pages/CEOCulture';
import HowItWorks from 'pages/HowItWorks';
import Contact from 'pages/Contact';
import PrivateRoutes from 'PrivateRoutes';


function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/themes" element={<Themes />} />
                    <Route path="/products/:slug" element={<Products />} />
                    <Route path="/product/detail/:slug" element={<ProductDetails />} />
                    <Route path="/ceo-culture" element={<CEOCulture />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route exact path='/' element={<PrivateRoutes />}>
                        <Route path="/profile/order" element={<Order />} />
                        <Route exact path='/profile/manage-address' element={<ManageAddress />} />
                        <Route exact path="/profile/manage-address/address" element={<AddAddress />} />
                        <Route exact path="/profile/manage-address/address/:id" element={<AddAddress />} />
                        <Route path="/checkout-page1" element={<CheckoutPage1 />} />
                        <Route path="/checkout-page2" element={<CheckoutPage2 />} />
                        <Route path="/checkout-page3" element={<CheckoutPage3 />} />
                    </Route>
                    <Route path="/profile/reset-password" element={<ResetPassowrd />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;
