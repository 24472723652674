import React, { useEffect, useState } from 'react';
import ResultCard from './ResultCard';

export default function Quiz(props) {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answerList, setAnswerList] = useState({});
    const [errorOption, setErrorOption] = useState(false);
    const [showResult, setShowResult] = useState(props.showResultMst);
    const [resultData, setResultData] = useState({});

    function handlePrev() {
        const nextQuestion = currentQuestion - 1;
        setCurrentQuestion(nextQuestion);
    }

    function handleNext(curQuesAns) {
        const res = checkValidation(curQuesAns);
        if (!res) {
            const nextQuestion = currentQuestion + 1;
            setCurrentQuestion(nextQuestion);
            setErrorOption(false);
        } else {
            setErrorOption(true);
        }
    }

    useEffect(() => {
        checkRadioValue();
    }, [currentQuestion])

    function checkRadioValue() {
        if (!answerList[currentQuestion]) {

            let options = props.quizState?.quiz?.data?.questions[currentQuestion]?.options;

            setAnswerList({
                ...answerList,
                [currentQuestion]: options[0].option_value
            })

        }
    }

    function handleDone(curQuesAns) {
        const res = checkValidation(curQuesAns);
        if (!res) {
            let total = 0;
            for (var prop in answerList) {
                total += parseInt(answerList[prop]);
            }
            const result = props.quizState.quiz.data.results;
            var ResultCardData = result[0];
            for (var key in result) {
                if (result[key]['quiz_min_score'] <= total && result[key]['quiz_max_score'] >= total) {
                    ResultCardData = result[key];
                }
            }
            setResultData(ResultCardData)
            setShowResult(true);
        } else {
            setErrorOption(true);
        }
    }

    function checkValidation(curQuesAns) {
        if (curQuesAns in answerList && answerList[curQuesAns]) {
            setErrorOption(false);
            return false;
        }
        setErrorOption(true);
        return true;
    }

    useEffect(() => {
  
    }, [answerList, resultData])


    function handleSelectOption(e) {
        const { name, value } = e.target;
        setAnswerList({
            ...answerList,
            [currentQuestion]: value
        })
        setErrorOption(false);
    }

    return (
        <>
            <div className={`popup-outer ${props.showQuiz ? 'show' : ''}`} id="quiz-popup">
                <div className="popup-overlay" />
                <div className="popup-sec">
                    <div className="popup-close" onClick={props.handleQuizClose} />
                    {!showResult ?
                        <div id="question">
                            <div className="popup-top">
                                <h4>
                                    {props.quizState?.quiz?.data?.questions[currentQuestion]?.title}
                                </h4>
                                <ul className="qus-list">
                                    <fieldset>
                                        {props.quizState?.quiz?.data?.questions[currentQuestion]?.options.map((item, index) => (
                                            <li key={`${currentQuestion}-${item.option_value}`}>
                                                <input
                                                    type="radio"
                                                    checked={answerList[currentQuestion] === item.option_value ? "checked" : ""}
                                                    className="custom_radio"
                                                    value={item.option_value}
                                                    id={`radio-${currentQuestion}-${item.option_value}`}
                                                    name={`answer_option_${currentQuestion}`}
                                                    onChange={handleSelectOption}
                                                />
                                                <label htmlFor={`radio-${currentQuestion}-${item.option_value}`}>{item.option_name}</label>
                                            </li>
                                        ))}
                                    </fieldset>
                                </ul>
                                {errorOption ? <p className="error text-center">Please select a option!</p> : ''}
                                <div className="text-center">
                                    {currentQuestion > 0 ? (
                                        <button className="button border small login" onClick={handlePrev}>Prev</button>
                                    ) : ''}
                                    {props.quizState?.quiz?.data?.questions.length > currentQuestion + 1 ? (
                                        <button className="button small" onClick={() => handleNext(currentQuestion)}>Next</button>
                                    ) : ''}
                                    {props.quizState?.quiz?.data?.questions.length === currentQuestion + 1 ? (
                                        <button className="button small jq-done" onClick={() => handleDone(currentQuestion)}>Done</button>
                                    ) : ''}
                                </div>
                            </div>
                            {/* bottom sec */}
                            <div className="popup-bottom">
                                <ul className="steps">
                                    {props.quizState?.quiz?.data?.questions?.map((item, index) => (
                                        currentQuestion === index ? <li key={index} className="yellow">{index + 1}</li> : currentQuestion > index ? <li key={index} className="green">{index + 1}</li> : <li key={index}>{index + 1}</li>
                                    ))}
                                </ul>
                                <p className="steps-info">{props.quizState?.quiz?.data?.questions[currentQuestion]?.description}</p>
                            </div>
                        </div> : ''}
                    {/* next */}
                    {showResult ? <ResultCard resultData={resultData} /> : ''}
                </div>
            </div>
        </>
    );
}
